import * as Types from '../../../../_generated-types/index';

import { gql } from '@apollo/client';
export const ContainerSiteTableRowFragmentDoc = gql`
  fragment ContainerSiteTableRow on ContainerSite {
    id
    name
    serviceLocation {
      id
      name
      account {
        id
        name
      }
    }
    binBar {
      id
      name
      batteryLevel
      firmwareConfig {
        maxOfflineSeconds
      }
    }
    lastLocation {
      latitude
      longitude
      radius
    }
    lastWeight {
      id
      timestamp
    }
    emptyContainerWeight(unit: $unit)
    size
    commodityType
    assignedDriver {
      id
      name
    }
    containerCycles(page: { page: 1, pageSize: 1 }) {
      id
      removal
      removalPhotos {
        id
        status
      }
    }
    installPhotos {
      id
      status
    }
    maxGrossWeight(unit: $unit)
    maxNetWeight(unit: $unit)
    maxDisplayWeight(display: $display) @client
    currentGrossWeight(unit: $unit)
    currentNetWeight(unit: $unit)
    currentDisplayWeight(display: $display) @client
  }
`;
export type ContainerSiteTableRowFragment = {
  __typename?: 'ContainerSite';
  id: string;
  name: string;
  emptyContainerWeight?: number | null;
  size?: number | null;
  commodityType?: string | null;
  maxGrossWeight?: number | null;
  maxNetWeight?: number | null;
  maxDisplayWeight?: number | null;
  currentGrossWeight?: number | null;
  currentNetWeight?: number | null;
  currentDisplayWeight?: number | null;
  serviceLocation: {
    __typename?: 'ServiceLocation';
    id: string;
    name: string;
    account: { __typename?: 'Account'; id: string; name: string };
  };
  binBar?: {
    __typename?: 'BinBar';
    id: string;
    name: string;
    batteryLevel?: number | null;
    firmwareConfig: {
      __typename?: 'FirmwareConfig';
      maxOfflineSeconds: number;
    };
  } | null;
  lastLocation?: {
    __typename?: 'CellularLocation';
    latitude: number;
    longitude: number;
    radius: number;
  } | null;
  lastWeight?: { __typename?: 'WeightPost'; id: string; timestamp: any } | null;
  assignedDriver?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
  } | null;
  containerCycles: Array<{
    __typename?: 'ContainerCycle';
    id: string;
    removal?: any | null;
    removalPhotos: Array<{
      __typename?: 'CloudStorageFile';
      id: string;
      status: Types.UploadStatus;
    }>;
  }>;
  installPhotos: Array<{
    __typename?: 'CloudStorageFile';
    id: string;
    status: Types.UploadStatus;
  }>;
};
