import { NotAvailable } from '@carbon/icons-react';
import { TableCell, TableRow, Tag } from '@carbon/react';
import { Permission, canForAccount } from '@wastewizer/authz';
import { ContainerSiteUtils } from '@wastewizer/kitchen-sink';
import { StatusTags, TableCellText } from '@wastewizer/ui-components';
import { useRouter } from 'next/navigation';
import ReactTimeAgo from 'react-timeago';

import { useUser } from '#hooks/useUser';
import styles from './ContainerSiteTableRow.module.scss';
import { ContainerSiteTableRowFragment } from '../_generated';
import { AssignBinBar } from '../AssignBinBar';
import { AssignDriver } from '../AssignDriver';
import { DeleteContainerSite } from '../DeleteContainerSite';
import { EditAlertSubscriptions } from '../EditAlertSubscriptions';
import { EditContainerSite } from '../EditContainerSite';
import { Fullness } from '../Fullness';
import { UnAssignBinBar } from '../UnAssignBinBar';

export type ContainerSiteTableRowProps = {
  row: ContainerSiteTableRowFragment;
  isGrouped: boolean;
  loading: boolean;
  afterUpdate: () => void;
  afterDelete: () => void;
  afterAssign: () => void;
  afterUnAssign: () => void;
};

export const ContainerSiteTableRow: React.FunctionComponent<
  ContainerSiteTableRowProps
> = ({
  row,
  isGrouped,
  loading,
  afterUpdate,
  afterDelete,
  afterAssign,
  afterUnAssign,
}) => {
  const user = useUser();
  const router = useRouter();

  const {
    preferences: { weightLabel },
  } = user;

  const canUpdate = canForAccount(
    user,
    row.serviceLocation?.account?.id,
    Permission.CAN_UPDATE_CONTAINER_SITES,
  );
  const canDelete = canForAccount(
    user,
    row.serviceLocation?.account?.id,
    Permission.CAN_DELETE_CONTAINER_SITES,
  );
  const canReadDetails = canForAccount(
    user,
    row.serviceLocation?.account?.id,
    Permission.CAN_READ_CONTAINER_SITE_DETAILS,
  );

  return (
    <TableRow
      {...(canReadDetails && {
        onClick: (e) => {
          if (e.ctrlKey || e.metaKey) {
            window.open(`/container-sites/${row.id}`, '_blank');
          } else {
            router.push(`/container-sites/${row.id}`);
          }
        },
      })}
    >
      <TableCell className={isGrouped ? styles.nameCell : undefined}>
        <TableCellText loading={loading}>
          <div className="ww--no-wrap">{row.name}</div>
          {row.binBar && <Tag size="sm">{row.binBar.name}</Tag>}
        </TableCellText>
      </TableCell>
      {!isGrouped && (
        <TableCell>
          <TableCellText loading={loading}>
            <div>{row.serviceLocation?.name}</div>
            <div className={styles.tdAccount}>
              {row.serviceLocation?.account?.name}
            </div>
          </TableCellText>
        </TableCell>
      )}
      <TableCell>
        <TableCellText loading={loading}>
          {!!row.binBar && !!row.lastWeight && (
            <div className="ww--no-wrap">
              {row.currentDisplayWeight} {weightLabel.pluralAbbrev}
            </div>
          )}
        </TableCellText>
      </TableCell>
      <TableCell>
        <TableCellText loading={loading}>
          {!!row.binBar && !!row.lastWeight && <Fullness containerSite={row} />}
        </TableCellText>
      </TableCell>
      <TableCell>
        <TableCellText loading={loading}>
          {!!row.binBar && !!row.lastWeight?.timestamp ? (
            <div className="ww--no-wrap">
              <ReactTimeAgo date={new Date(row.lastWeight.timestamp)} />
            </div>
          ) : (
            !!row.binBar && 'Unknown'
          )}
        </TableCellText>
      </TableCell>
      <TableCell>
        <TableCellText loading={loading}>
          {!!row.binBar?.batteryLevel && (
            <div className="ww--no-wrap">
              {Math.min(row.binBar?.batteryLevel * 100, 100).toFixed(0)}%
            </div>
          )}
        </TableCellText>
      </TableCell>
      <TableCell>
        <TableCellText loading={loading}>
          <div
            className={styles.editDriverColumn}
            onClick={(e) => e.stopPropagation()}
          >
            {canUpdate && <AssignDriver containerSite={row} />}
            {!!row.assignedDriver && row.assignedDriver.name}
          </div>
        </TableCellText>
      </TableCell>
      <TableCell>
        <TableCellText loading={loading}>
          {!!row.binBar && (
            <StatusTags
              isTraining={false}
              isOffline={
                !!row.lastWeight &&
                ContainerSiteUtils.isOffline(
                  row.binBar.firmwareConfig.maxOfflineSeconds * 1_000,
                  new Date(row.lastWeight.timestamp),
                )
              }
              batteryLevel={row.binBar.batteryLevel}
              currentWeight={row.currentGrossWeight}
              maxWeight={row.maxGrossWeight}
            />
          )}
        </TableCellText>
      </TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <TableCellText loading={loading}>
          <EditAlertSubscriptions containerSite={row} />
        </TableCellText>
      </TableCell>
      <TableCell className="ww--no-wrap" onClick={(e) => e.stopPropagation()}>
        {!canUpdate && !canDelete ? (
          <div className="ww--div_not-available">
            <NotAvailable size={20} title="Not available" />
          </div>
        ) : (
          <>
            {canUpdate && (
              <EditContainerSite
                containerSite={row}
                afterUpdate={afterUpdate}
              />
            )}
            {canUpdate && !row.binBar && (
              <AssignBinBar containerSite={row} afterAssign={afterAssign} />
            )}
            {canUpdate && !!row.binBar && (
              <UnAssignBinBar
                containerSite={row}
                afterUnAssign={afterUnAssign}
              />
            )}
            {canDelete && (
              <DeleteContainerSite
                containerSite={row}
                afterDelete={afterDelete}
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
