import React, { Fragment } from 'react';

import { Checkmark, Information, Menu } from '@carbon/icons-react';
import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  PaginationSkeleton,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
  TableToolbarAction,
  TableToolbarMenu,
} from '@carbon/react';
import { Permission, canForAccount, canForAnyAccount } from '@wastewizer/authz';
import { TableCellText } from '@wastewizer/ui-components';
import { mediaQueries } from '@wastewizer/ui-constants';
import { sentence } from 'case';
import { useRouter } from 'next/navigation';
import {
  useQueryParam,
  StringParam,
  NumberParam,
  withDefault,
  encodeString,
  decodeString,
  encodeObject,
  decodeObject,
} from 'use-query-params';
import {
  useDebounceCallback,
  useMediaQuery,
  useLocalStorage,
} from 'usehooks-ts';

import {
  ContainerSiteFilterInput,
  ContainerSiteGroupInput,
  ContainerSiteSort,
  SortDirection,
  WeightDisplay,
} from '#generated-types';
import { useUser } from '#hooks/useUser';
import { useSearchContainerSitesQuery } from './_generated';
import styles from './ContainerSiteTable.module.scss';
import { ContainerSiteTableRowFragment } from '../_generated';
import { ContainerSitesFilter } from '../ContainerSitesFilter';
import { ContainerSiteTableRow } from '../ContainerSiteTableRow';
import { CreateContainerSite } from '../CreateContainerSite';
import { Map } from '../Map';
import { MobileCard } from '../MobileCard';
import { NoContainersHint } from '../NoContainersHint';

export const ContainerSitesTable: React.FunctionComponent = () => {
  const user = useUser();
  const isSm = useMediaQuery(mediaQueries.sm);
  const router = useRouter();

  const {
    preferences: { weightUnit, weightDisplay },
  } = user;

  const [showMap, setShowMap] = useLocalStorage<boolean>(
    'showContainerMap',
    true,
  );
  const [group, setGroup] = useLocalStorage<ContainerSiteGroupInput | null>(
    'groupContainerSites',
    null,
  );

  const [search, setSearch] = useQueryParam<string>(
    'search',
    withDefault(StringParam, ''),
  );

  const [filter, setFilter] = useQueryParam<ContainerSiteFilterInput[]>(
    'filter',
    withDefault(
      {
        encode: (params: ContainerSiteFilterInput[] | null) =>
          params?.map((p) => encodeObject(p, ':', '|')).join(','),

        decode: (paramStr: string | null) => {
          if (paramStr === '') {
            return [];
          }

          return paramStr
            ?.split(',')
            .map((p) =>
              decodeObject(p, ':', '|'),
            ) as ContainerSiteFilterInput[];
        },
        equals: (
          a: ContainerSiteFilterInput[],
          b: ContainerSiteFilterInput[],
        ) => JSON.stringify(a) === JSON.stringify(b),
      },
      null,
    ),
  );

  const [page, setPage] = useQueryParam<number>(
    'page',
    withDefault(NumberParam, 1),
  );

  const [pageSize, setPageSize] = useQueryParam<number>(
    'pageSize',
    withDefault(NumberParam, 25),
  );

  const [sortBy, setSortBy] = useQueryParam<ContainerSiteSort>(
    'sortBy',
    withDefault(
      {
        encode: (param: ContainerSiteSort | null) => encodeString(param),

        decode: (paramStr: string | null) =>
          decodeString(paramStr) as ContainerSiteSort,
      },
      ContainerSiteSort.Name,
    ),
  );

  const [sortDirection, setSortDirection] = useQueryParam<SortDirection>(
    'sortDirection',
    withDefault(
      {
        encode: (param: SortDirection | null) => encodeString(param),

        decode: (paramStr: string | null) =>
          decodeString(paramStr) as SortDirection,
      },
      SortDirection.Asc,
    ),
  );

  const { data, loading, refetch } = useSearchContainerSitesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      filter,
      page: {
        page,
        pageSize,
      },
      sort: {
        sortBy,
        sortDirection,
      },
      group,
      unit: weightUnit,
      display: weightDisplay,
    },
  });

  const setSearchDebounced = useDebounceCallback((newValue) => {
    setSearch(newValue);
    refetch();
  }, 300);

  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target) {
      return;
    }

    const { value } = target;
    setPage(1); // reset to page 1 whenever search term changes
    setSearchDebounced(value);
  };

  const handleFilter = (newFilter: ContainerSiteFilterInput[]) => {
    setPage(1); // reset to page 1 whenever search term changes
    setFilter(newFilter);
    refetch();
  };

  const handleSort = (sort: ContainerSiteSort) => {
    let direction = SortDirection.Asc;
    if (sortBy === sort) {
      direction =
        sortDirection === SortDirection.Asc
          ? SortDirection.Desc
          : SortDirection.Asc;
    }
    setSortBy(sort);
    setSortDirection(direction);
    refetch();
  };

  const handlePagination = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setPage(page);
    setPageSize(pageSize);
    refetch();
  };

  const headers: Array<{
    key: string;
    name: string;
    sortKey?: ContainerSiteSort;
    isSortHeader?: boolean;
    render?: React.ReactNode;
  }> = [
    {
      key: 'name',
      name: 'Name',
      sortKey: ContainerSiteSort.Name,
      isSortHeader: sortBy === ContainerSiteSort.Name,
    },
  ];

  if (!isSm) {
    if (!group) {
      headers.push({
        key: 'serviceLocation',
        name: 'Service location',
        sortKey: ContainerSiteSort.ServiceLocation,
        isSortHeader: sortBy === ContainerSiteSort.ServiceLocation,
      });
    }
    // Add all other columns
    headers.push(
      {
        key: 'weight',
        name: 'Weight',
        render: (
          <div className="ww--toggle-tip">
            <span>{sentence(weightDisplay)} weight</span>{' '}
            {weightDisplay === WeightDisplay.Net && (
              <Toggletip align="bottom">
                <ToggletipButton label="Show information">
                  <Information />
                </ToggletipButton>
                <ToggletipContent>
                  <p>
                    The estimated net weight is calculated using the empty
                    container weight. Please update the empty container weight
                    for each container site to see a more accurate estimate
                  </p>
                </ToggletipContent>
              </Toggletip>
            )}
          </div>
        ),
      },
      { key: 'fullness', name: 'Fullness' },
      { key: 'lastSync', name: 'Last sync' },
      { key: 'battery', name: 'Battery' },
      { key: 'driver', name: 'Driver' },
      { key: 'status', name: 'Status' },
      { key: 'notifications', name: 'Notifications' },
      { key: 'actions', name: 'Actions' },
    );
  }

  const rows: ContainerSiteTableRowFragment[] =
    data?.containerSites ||
    Array.from(Array(pageSize)).map(
      (_, i) => ({ id: i.toString() }) as ContainerSiteTableRowFragment,
    ); // for loading state
  const count = data?.count !== undefined ? data.count : pageSize;

  const canCreate = canForAnyAccount(
    user,
    Permission.CAN_CREATE_CONTAINER_SITES,
  );

  return (
    <>
      <TableContainer title="Container sites">
        <TableToolbar>
          <TableToolbarContent>
            <TableToolbarSearch
              persistent
              placeholder={
                isSm
                  ? 'Search'
                  : 'Search by name, service location, account, or BinBar'
              }
              defaultValue={search}
              onChange={handleSearch}
            />
            <ContainerSitesFilter
              initialFilter={filter}
              onFilter={handleFilter}
            />
            <TableToolbarMenu renderIcon={Menu} iconDescription="Options">
              <TableToolbarAction
                onClick={() => {
                  if (group) {
                    setGroup(null);
                  } else {
                    setGroup({ groupBy: ContainerSiteSort.ServiceLocation });
                  }
                }}
              >
                <div className={styles.toolbarActionText}>Group results</div>
                {group ? <Checkmark /> : ''}
              </TableToolbarAction>
              <TableToolbarAction
                onClick={() => {
                  setShowMap(!showMap);
                }}
              >
                <div className={styles.toolbarActionText}>Show map</div>
                {showMap ? <Checkmark /> : ''}
              </TableToolbarAction>
            </TableToolbarMenu>
            {canCreate && <CreateContainerSite afterCreate={refetch} />}
          </TableToolbarContent>
        </TableToolbar>
        {!isSm && showMap && (
          <Map loading={loading} containerSites={data?.containerSites || []} />
        )}
        <Table isSortable>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableHeader
                  key={header.key}
                  isSortable={Boolean(header.sortKey)}
                  isSortHeader={header.isSortHeader}
                  sortDirection={sortDirection}
                  onClick={() => handleSort(header.sortKey)}
                >
                  {header.render ? header.render : header.name}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              const currentGroup = row.serviceLocation?.name;
              const previousGroup = rows[idx - 1]?.serviceLocation?.name;
              const createGrouping = !!group && currentGroup !== previousGroup;

              const canReadDetails = canForAccount(
                user,
                row.serviceLocation?.account?.id,
                Permission.CAN_READ_CONTAINER_SITE_DETAILS,
              );

              return isSm ? (
                <TableRow
                  key={row.id}
                  {...(canReadDetails && {
                    onClick: () => {
                      router.push(`/container-sites/${row.id}`);
                    },
                  })}
                >
                  <TableCell>
                    <MobileCard loading={loading} containerSite={row} />
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment key={row.id}>
                  {createGrouping && (
                    <TableRow className={styles.groupRow}>
                      <TableCell colSpan={headers.length}>
                        <TableCellText loading={loading}>
                          <>
                            <strong>{row.serviceLocation?.name}</strong>
                            {' | '}
                            {row.serviceLocation?.account?.name}
                          </>
                        </TableCellText>
                      </TableCell>
                    </TableRow>
                  )}
                  <ContainerSiteTableRow
                    row={row}
                    isGrouped={!!group}
                    loading={loading}
                    afterUpdate={refetch}
                    afterDelete={refetch}
                    afterAssign={refetch}
                    afterUnAssign={refetch}
                  />
                </Fragment>
              );
            })}
            {!rows.length && (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <NoContainersHint />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {loading && <PaginationSkeleton />}

      {!loading && (
        <Pagination
          isLastPage={rows.length < pageSize}
          page={page}
          pageSize={pageSize}
          pageSizes={[10, 25, 50, 100]}
          totalItems={count}
          onChange={handlePagination}
        />
      )}
    </>
  );
};
