import { useState } from 'react';

import { SkeletonPlaceholder, Stack } from '@carbon/react';
import { Permission, canForAccount } from '@wastewizer/authz';
import { ContainerSiteUtils } from '@wastewizer/kitchen-sink';
import { StatusTags, UploadPhotos } from '@wastewizer/ui-components';
import ReactTimeAgo from 'react-timeago';

import { UploadStatus } from '#generated-types';
import { useUser } from '#hooks/useUser';
import {
  useGetInstallPhotosLazyQuery,
  useGetRemovalPhotosLazyQuery,
  useUploadInstallPhotosMutation,
  useUploadRemovalPhotosMutation,
} from './_generated';
import styles from './MobileCard.module.scss';
import { ContainerSiteTableRowFragment } from '../_generated';
import { EditAlertSubscriptions } from '../EditAlertSubscriptions';
import { Fullness } from '../Fullness';

export type MobileCardProps = {
  loading: boolean;
  containerSite: ContainerSiteTableRowFragment;
};

export const MobileCard: React.FunctionComponent<MobileCardProps> = ({
  loading,
  containerSite,
}) => {
  const user = useUser();

  const [isUploadingInstallPhotos, setIsUploadingInstallPhotos] =
    useState(false);
  const [isUploadingRemovalPhotos, setIsUploadingRemovalPhotos] =
    useState(false);

  const [uploadInstallPhotos] = useUploadInstallPhotosMutation();
  const [uploadRemovalPhotos] = useUploadRemovalPhotosMutation();

  const [
    _install,
    {
      startPolling: startPollingForInstallPhotos,
      stopPolling: stopPollingForInstallPhotos,
    },
  ] = useGetInstallPhotosLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: containerSite.id,
    },
    onCompleted: (data) => {
      const { installPhotos } = data.containerSite;
      if (
        installPhotos.every((photo) => photo.status !== UploadStatus.Uploading)
      ) {
        setIsUploadingInstallPhotos(false);
        stopPollingForInstallPhotos();
      }
    },
  });

  const [
    _removal,
    {
      startPolling: startPollingForRemovalPhotos,
      stopPolling: stopPollingForRemovalPhotos,
    },
  ] = useGetRemovalPhotosLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: containerSite.containerCycles?.[0]?.id,
    },
    onCompleted: (data) => {
      const { removalPhotos } = data.containerSite;
      if (
        removalPhotos.every((photo) => photo.status !== UploadStatus.Uploading)
      ) {
        setIsUploadingRemovalPhotos(false);
        stopPollingForRemovalPhotos();
      }
    },
  });

  const handleInstallFileUpload = async (files: File[]) => {
    const containerSiteId = containerSite.id;
    const fileNames = files.map((file) => file.name);

    const { data } = await uploadInstallPhotos({
      variables: {
        id: containerSiteId,
        fileNames,
      },
    });

    setIsUploadingInstallPhotos(true);

    await Promise.all(
      files.map(async (file, idx) => {
        const cloudStorageFileUpload =
          data.uploadContainerSiteInstallPhotos[idx];

        // upload to GCS with the signed url
        await fetch(cloudStorageFileUpload.uploadUri, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
            'x-goog-meta-container-site-id': containerSiteId,
            'x-goog-meta-file-id': cloudStorageFileUpload.id,
          },
          body: file,
        });
      }),
    );

    // poll for the status of the uploaded files
    startPollingForInstallPhotos(3000);
  };

  const handleRemovalFileUpload = async (files: File[]) => {
    const containerCycleId = containerSite.containerCycles[0].id;
    const fileNames = files.map((file) => file.name);

    const { data } = await uploadRemovalPhotos({
      variables: {
        id: containerCycleId,
        fileNames,
      },
    });

    setIsUploadingRemovalPhotos(true);

    await Promise.all(
      files.map(async (file, idx) => {
        const cloudStorageFileUpload =
          data.uploadContainerCycleRemovalPhotos[idx];

        // upload to GCS with the signed url
        await fetch(cloudStorageFileUpload.uploadUri, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
            'x-goog-meta-container-cycle-id': containerCycleId,
            'x-goog-meta-file-id': cloudStorageFileUpload.id,
          },
          body: file,
        });
      }),
    );

    // poll for the status of the uploaded files
    startPollingForRemovalPhotos(3000);
  };

  if (loading || !containerSite) {
    return (
      <div className={styles['skeleton-padding']}>
        <SkeletonPlaceholder className="ww--skeleton_full-width" />
      </div>
    );
  }

  const {
    preferences: { weightLabel },
  } = user;
  const hasBinBar = !!containerSite.binBar;
  const currentGrossWeight = containerSite.currentGrossWeight;
  const currentNetWeight = containerSite.currentNetWeight;
  const batteryLevel = containerSite.binBar?.batteryLevel;

  const canUploadInstallPhotos = canForAccount(
    user,
    containerSite.serviceLocation?.account?.id,
    Permission.CAN_UPLOAD_INSTALL_PHOTOS,
  );

  const canUploadRemovalPhotos =
    canForAccount(
      user,
      containerSite.serviceLocation?.account?.id,
      Permission.CAN_UPLOAD_REMOVAL_PHOTOS,
    ) &&
    containerSite.containerCycles.length && // Has a container cycle
    !containerSite.containerCycles[0].removal; // Most recent does not have a removal date yet

  return (
    <div className={styles['mobile-card']}>
      <Stack>
        <Stack gap={4} orientation="horizontal" className={styles['header']}>
          <div>{containerSite.name}</div>

          <div onClick={(e) => e.stopPropagation()} title="Notifications">
            <EditAlertSubscriptions containerSite={containerSite} />
          </div>
        </Stack>
      </Stack>

      <Stack>
        <Stack gap={4} orientation="horizontal">
          <div className={styles['sub-header']}>
            {containerSite.serviceLocation?.name}
            {' | '}
            {containerSite.serviceLocation?.account?.name}
          </div>
        </Stack>
      </Stack>

      {!hasBinBar && <div>No BinBar assigned to this container site</div>}
      {hasBinBar && (
        <Stack gap={4}>
          <div className={styles['sub-header']}>
            {containerSite.binBar?.name}
          </div>

          <div>
            <StatusTags
              isTraining={false}
              isOffline={
                !!containerSite.lastWeight &&
                ContainerSiteUtils.isOffline(
                  containerSite.binBar.firmwareConfig.maxOfflineSeconds * 1_000,
                  new Date(containerSite.lastWeight.timestamp),
                )
              }
              batteryLevel={containerSite.binBar.batteryLevel}
              currentWeight={containerSite.currentGrossWeight}
              maxWeight={containerSite.maxGrossWeight}
            />
          </div>

          <Stack gap={4} orientation="horizontal">
            <div>
              <div>
                <strong>Gross</strong>
              </div>
              {currentGrossWeight !== null && (
                <>
                  {currentGrossWeight} {weightLabel.pluralAbbrev}
                </>
              )}
            </div>

            <div>
              <div>
                <strong>Net</strong>
              </div>
              {currentNetWeight !== null && (
                <>
                  {currentNetWeight} {weightLabel.pluralAbbrev}
                </>
              )}
            </div>

            <div>
              <div>
                <strong>Battery</strong>
              </div>
              {batteryLevel !== undefined && (
                <>{Math.min(batteryLevel * 100, 100).toFixed(0)}%</>
              )}
            </div>
          </Stack>

          <div>
            <div>
              <strong>Fullness</strong>
            </div>
            <Fullness containerSite={containerSite} showHelper={false} />
          </div>

          <div>
            <strong>Last sync</strong>{' '}
            {containerSite.lastWeight?.timestamp ? (
              <ReactTimeAgo
                date={new Date(containerSite.lastWeight.timestamp)}
              />
            ) : (
              'Unknown'
            )}
          </div>

          <Stack gap={4} orientation="horizontal" className={styles.uploads}>
            {canUploadInstallPhotos && (
              <div onClick={(e) => e.stopPropagation()}>
                <UploadPhotos
                  label="Install photos"
                  photoCount={containerSite.installPhotos.length}
                  isUploading={isUploadingInstallPhotos}
                  onFilesSelected={handleInstallFileUpload}
                />
              </div>
            )}

            {canUploadRemovalPhotos && (
              <div onClick={(e) => e.stopPropagation()}>
                <UploadPhotos
                  label="Removal photos"
                  photoCount={
                    containerSite.containerCycles[0].removalPhotos.length
                  }
                  isUploading={isUploadingRemovalPhotos}
                  onFilesSelected={handleRemovalFileUpload}
                />
              </div>
            )}
          </Stack>
        </Stack>
      )}
    </div>
  );
};
