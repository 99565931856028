import React, {
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Permission, canForAccount } from '@wastewizer/authz';
import { ContainerMap, ContainerMapMarker } from '@wastewizer/ui-components';
import { useRouter } from 'next/navigation';

import { useUser } from '#hooks/useUser';
import styles from './Map.module.scss';
import { ContainerSiteTableRowFragment } from '../_generated';

export type MapProps = {
  loading: boolean;
  containerSites: ContainerSiteTableRowFragment[];
};

export const Map: React.FunctionComponent<MapProps> = ({
  loading,
  containerSites,
}) => {
  const user = useUser();
  const router = useRouter();
  const mapRef = useRef<HTMLDivElement>();
  const [mapWidth, setMapWidth] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);

  useLayoutEffect(() => {
    if (mapRef.current) {
      setMapWidth(mapRef.current.offsetWidth);
      setMapHeight(mapRef.current.offsetHeight);
    }
  }, [mapRef.current?.offsetWidth, mapRef.current?.offsetHeight]);

  const Sizing = ({
    mapRef,
    children,
  }: {
    mapRef: MutableRefObject<HTMLDivElement>;
    children: React.ReactNode;
  }) => (
    <div ref={mapRef} className={styles['map-container']}>
      {children}
    </div>
  );

  return (
    <Sizing mapRef={mapRef}>
      <ContainerMap
        loading={loading}
        markers={(containerSites || [])
          .filter(
            (site) =>
              !!site.lastLocation?.latitude && !!site.lastLocation?.longitude,
          )
          .map((site) => {
            const canReadDetails =
              !!user &&
              canForAccount(
                user,
                site.serviceLocation.account.id,
                Permission.CAN_READ_CONTAINER_SITE_DETAILS,
              );

            return {
              renderMarker: () => (
                <ContainerMapMarker
                  containerSiteName={site.name}
                  serviceLocationName={site.serviceLocation.name}
                  maxWeight={site.maxDisplayWeight}
                  weight={site.currentDisplayWeight}
                  weightLabel={user.preferences.weightLabel?.plural}
                  weightDisplay={user.preferences.weightDisplay?.toLowerCase()}
                  onClick={() => {
                    if (canReadDetails) {
                      router.push(`/container-sites/${site.id}`);
                    }
                  }}
                />
              ),
              lat: site.lastLocation.latitude,
              lng: site.lastLocation.longitude,
            };
          })}
        width={mapWidth}
        height={mapHeight}
      />
    </Sizing>
  );
};
